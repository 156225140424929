import React, { useEffect, useState } from 'react'

import './skeleton.css'
import './normalize.css'

export const Column = (props) => {
	const [width, setWidth] = useState();
	const [offset, setOffset] = useState();
	const [clss, setClss] = useState();

	useEffect(() => {
		setWidth( props.width ? stringToNum(props.width) + " columns" : "column" );
		setOffset( props.offset ? "offset-by-" + stringToNum(props.offset) : undefined );
		setClss( props.clss )
	}, [])

	const stringToNum = (w) => {
		let a;
		switch(w){
			case "":
				a = null; break;
			case "1/2":
				a = "half"; break;
			case "1/3":
				a = "one-third"; break;
			case "2/3":
				a = "two-thirds"; break;
			case "1":
				a = "one"; break;
			case "2":
				a = "two"; break;
			case "3":
				a = "three"; break;
			case "4":
				a = "four"; break;
			case "5":
				a = "five"; break;
			case "6":
				a = "six"; break;
			case "7":
				a = "seven"; break;
			case "8":
				a = "eight"; break;
			case "9":
				a = "nine"; break;
			case "10":
				a = "ten"; break;
			case "11":
				a = "eleven"; break;
			case "12":
				a = "twelve"; break;
			default:
				a = null; break;
		}
		return a;
	}
	return (
		<div className = {width + (offset ? " " + offset : "") + (clss ? " " + clss : "")}>
			{props.children}
		</div>
	)
}

export const Row = (props) => {
	const [clss, setClss] = useState();

	useEffect(() => {
		setClss( props.clss )
	})

	return (
		<div className = {clss ? "row " + clss : "row"}>
			{props.children}
		</div>
	)
}

export const Container = (props) => {
	const [clss, setClss] = useState();

	useEffect(() => {
		setClss( props.clss )
	})

	return (
		<div className = {clss ? "container " + clss : "container"} onKeyDown={props.onKeyDown} tabIndex={0}>
			{props.children}
		</div>
	)
}
import React, { Fragment } from 'react'
import { Row } from '../components/skeleton.jsx'

const EndScreen = (props) => {
	return (
		<Fragment>
			<Row>
				<h2 className='title_block'>Done!</h2>
			</Row>
			<Row>
				<form className="form_fields">
					<label>Thank you for completing {props.system}'s {props.list == "preflight" ? "Pre-Flight" : "Post-Install"} checklist.</label>
				</form>
			</Row>
			<Row>
				<h2 className='title_block'>{props.allUploaded ? "All uploaded" : "Not all uploaded"}</h2>
			</Row>
			{ !props.allUploaded &&
				<Row>
					<form className="form_fields">
						<label>Please go someplace with an internet connection. The app will automatically upload any unloaded data.</label>
					</form>
				</Row>
			}
			
		</Fragment>
	);
}

export default EndScreen;
// FROM: https://stackoverflow.com/questions/635706/how-to-scroll-to-an-element-inside-a-div
// TODO: EVALUATE CODE AND REFACTOR
export const getRelativePos = (element) => {
	let pPos = element.parentNode.getBoundingClientRect(), // parent pos
		cPos = element.getBoundingClientRect(); // target pos
	let pos = {
		top: cPos.top - pPos.top + element.parentNode.scrollTop,
		right: cPos.right  - pPos.right,
		bottom: cPos.bottom - pPos.bottom,
		left: cPos.left   - pPos.left
	};
	return pos;
}

export const scrollTo = (element, to, duration, onDone) => {
	let start = element.scrollTop,
		change = to - start,
		startTime = performance.now(),
		now, elapsed, t;

	const animateScroll = () => {
		now = performance.now();
		elapsed = (now - startTime)/1000;
		t = (elapsed/duration);

		element.scrollTop = start + change * easeInOutQuad(t);

		if( t < 1 ) {
			window.requestAnimationFrame(animateScroll);
		} else {
			onDone && onDone();
		}
	};
	animateScroll();
}

const easeInOutQuad = (t) => { return t<.5 ? 2*t*t : -1+(4-2*t)*t };
// END OF STACKOVERFLOW CODE
import React, { useState, useEffect, useRef, useContext, Fragment } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

//import { DataContext, OnlineContext, ImagesContext } from '../app.jsx'
import { AppContext } from '../app.jsx'
import { Container, Row, Column } from '../components/skeleton.jsx'
import ImagePreview from '../components/image_preview.jsx'
import EndScreen from '../components/end_screen.jsx'
import Topbar from '../components/topbar.jsx'
import { getRelativePos, scrollTo } from "../components/scrollFunctions.js"

import uploading from "../assets/icons/icon_uploading_grey_large.svg"
const projects_url = "https://n42rss2i5d.execute-api.us-east-1.amazonaws.com/Prod/project/?project="
const system_url = "https://n42rss2i5d.execute-api.us-east-1.amazonaws.com/Prod/system?project="
const signer_url = "https://n42rss2i5d.execute-api.us-east-1.amazonaws.com/Prod/system/files"
const regex = /[^-A-Za-z0-9]/g;

const StepPage = (props) => {
	const { project, list, system, step } = useParams();
	const [passed_data, isOnline,,, Images] = useContext(AppContext);

	const updateProjectData = props.parentProjCallback;
	const updateSystemData = props.parentSysCallback;
	const updateUploadTime = props.parentUploadTimeCallback;

	//const passed_data = useContext(DataContext),
	//	isOnline = useContext(OnlineContext),
	//	Images = useContext(ImagesContext);
	
	const [isLoaded, setIsLoaded] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [hasChanged, setHasChanged] = useState(false); // ONLY NEEDED FOR IMAGE ARRAYS
	const [roomNumber, setRoom] = useState("");
	const [sku, setSku] = useState("");
	const [data, setData] = useState();
	const [val, setVal] = useState(); // WILL HAVE TO BE LOOKED AT
	const [comments, setComments] = useState();
	const navigate = useNavigate();

	const contentTopRef = useRef();
	const contentBottomRef = useRef();

	const status_style = {
		fontSize: "3rem",
		textAlign: "left",
		fontWeight: 600,
		marginLeft: "1rem",
		marginBottom: "0%",
		color: "white"
	}

	const status_style_background = {
		width: "100%",
		height: "4rem",
		backgroundColor: isOnline ? "#95CE95" : "#DA9D8C"
	}

	const routeChangeNext = () => {
		if(Number(step) <= data.length - 1){
			// IF THE NEXT STEP DEPENDS ON THE ANSWER, GO TO THAT STEP
			// OTHERWISE MOVE TO NEXT STEP
			let next_step;
			if(data[step].next_step){
				if(data[step].next_step[val]){
					next_step = data[step].next_step[val];
				} else if(data[step].next_step){
					// SHOULD ONLY BE VALID FOR DEMO
					next_step = data[step].next_step;
				} else {
					next_step = Number(step) + 1;
				}
			} else {
				next_step = Number(step) + 1;
			}
			navigate('/system/' + project + "/" + list + "/" + system + "/" + next_step);
		} else {
			navigate('/project/' + project);
		}
	}
	const routeChangePrev = () => {
		if(Number(step) > 0){
			// IF THERE IS A SPECIFIED PREVIOUS STEP, GO TO THAT STEP
			// OTHERWISE MOVE TO PREVIOUS STEP
			let prev_step;
			if(data[step] && data[step].prev_step) {
				if(data[step].prev_step_DEPENDENT){
					let dependent_step = data[step].prev_step_DEPENDENT;
					let dependent_step_val = data[dependent_step].value;
					prev_step = data[step].prev_step[dependent_step_val];
				} else {
					prev_step = data[step].prev_step;
				}
			} else {
				prev_step = Number(step) - 1;
			}
			navigate('/system/' + project + "/" + list + "/" + system + "/" + prev_step);
		} else {
			navigate('/project/' + project);
		}
	}

	const scrollToTop = () => {
		contentTopRef.current.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	}

	const scrollToBottom = () => {
		if(contentBottomRef.current != null){
			let pos = getRelativePos(contentBottomRef.current);
			scrollTo(contentBottomRef.current.parentNode, pos.top , 0.5);
		}
	}

	useEffect(() => {
		document.title = `Ori | Punchlist - ${system} ${list == "preflight" ? "Pre-Install" : "Post-Install"}`
		scrollToTop()
		if(passed_data == null || passed_data.length == 0){
			//console.log("fetching data")
			fetch(projects_url + project)
				.then(res => res.json())
				.catch(err => setHasError(true))
				.then((result) => {
					let arr = result.sort((a,b) => (a.room > b.room) ? 1 : -1);
					result = arr;
					updateProjectData(result, project)
					let individual_sys = result.find(sys => sys.system === system);
					setRoom(individual_sys.room); setSku(individual_sys.sku);
					let sys_data = individual_sys[list];
					if(step <= sys_data.length -1 && sys_data[step].value != null){
						setVal(sys_data[step].value);
						if(sys_data[step].comments != null){
							setComments(sys_data[step].comments);
						}
					} else {
						setVal("");
						setComments("")
					}
					setData(sys_data); setIsLoaded(true);
					setHasError(false);
				});
		} else {
			//console.log("parsing data passed from APP")
			let individual_sys = passed_data.find(sys => sys.system === system);
			setIsUploading(false); setHasChanged(false);
			if(!roomNumber || !sku){
				setRoom(individual_sys.room); setSku(individual_sys.sku);
			}
			if(!isLoaded){
				setIsLoaded(true); setHasError(false);
			}
			let sys_data = individual_sys[list];
			setData(sys_data);
			if(step <= sys_data.length -1 && sys_data[step].value != null){
				// IF THERE'S DATA ASSOCIATED WITH THE STEP
				setVal(sys_data[step].value);
				sys_data[step].comments != null ? setComments(sys_data[step].comments) : setComments("");
			} else {
				setVal("");
				setComments("")
			}
		}
	}, [project, list, system, step, passed_data])
	
	const processFiles = (e) => {
		if(e.target.files.length <= data[step].max){
			let file_arr = Array.from(e.target.files);
			if(val.length > 0){
				let current_files = [...val];
				file_arr.forEach((file, i) => {
					if(!current_files.some(e => e.name === file.name && e.lastModified === file.lastModified)){
						// appends to existing array if new file
						current_files.push(file)
					}
					if(i == file_arr.length - 1 && current_files.length <= data[step].max){
						setVal(current_files)
					} else if(i == file_arr.length - 1){
						alert("You can only upload up to " + data[step].max + " images.")
					}
				})
			} else {
				setVal(file_arr)
			}
		} else {
			alert("You can only upload up to " + data[step].max + " images.")
		}
	}

	const removePic = (file_ind) => {
		let current_files = [...val];
		let new_array = current_files.filter((file, i) => { return file_ind != i});
		setHasChanged(true);
		setVal(new_array);
	}

	const getIndicies = (placeholder) => {
		let indicies = [];
		for(let i = 0; i < placeholder.length - 1; i++){
			if(placeholder[i] === "-") indicies.push(i)
		}
		return indicies;
	}

	const evaluateRegExInput = (input, indices) => {
		let new_input = input.toUpperCase().replace(regex, "");
		if(input.length >= val.length){
			if(indices.includes(input.length)){
				new_input += "-"
			}
			setVal(new_input)
		} else {
			if(indices.includes(input.length)){
				new_input = new_input.slice(0, -1);
			}
			setVal(new_input)
		}
	}

	const insertInputs = () => {
		switch (data[step].type){
			case "string": {
				if("regex" in data[step]){
					const indices = getIndicies(data[step].placeholder);
					//console.log(indices)
					return (
						<Fragment>
							<input autoComplete="off" type="text" className="regexed" id={data[step].id} value={val} onChange={e => {scrollToBottom(); evaluateRegExInput(e.target.value, indices)}} maxLength={data[step].placeholder.length} placeholder={data[step].placeholder} name="name" size="20" />
							{(val.length > 0 && !val[0].name && !val.match(data[step].regex)) && <p className="warning">Not a valid input</p> }
						</Fragment>
					)
				} else {
					return (
						<input autoComplete="off" type="text" id={data[step].id} value={val} onChange={e => {scrollToBottom(); setVal(e.target.value); }} maxLength={data[step].placeholder.length} placeholder={data[step].placeholder} name="name" size="20" />
					)
				}
			}
			case "check": {
				return (
					<div>
						<Column width="6">
							<input type="radio" value="Yes" onChange={
								e => {
									scrollToBottom();
									setVal(e.target.value);
									if(data[step].next_step){
										// IF THE VALUE WAS CHANGED, REMOVE DATA FOR OPPOSING STEP
										let opposing_step = data[step].next_step["No"];
										if(data[opposing_step].value) { data[opposing_step].value = null }
									}
								}
							} checked={val == "Yes"}/> Yes
						</Column>
						<Column width="6">
							<input type="radio" value="No" onChange={
								e => {
									scrollToBottom();
									setVal(e.target.value);
									if(data[step].next_step){
										// IF THE VALUE WAS CHANGED, REMOVE DATA FOR OPPOSING STEP
										let opposing_step = data[step].next_step["Yes"];
										if(data[opposing_step].value) { data[opposing_step].value = null }
									}
								}
							} checked={val == "No"}/> No
						</Column>
						{val == "No" && <Column width="12">
							<textarea autoComplete="off" value={comments} onChange={e => {scrollToBottom(); setComments(e.target.value);}} placeholder={data[step].comments_placeholder ? data[step].comments_placeholder: "Comments"}/>
						</Column>}
					</div>
				)
			}
			case "picture": {
				return (
					<Fragment>
						<label style={{marginBottom: "0.5rem"}}><i>(Max of <strong>{data[step].max} {data[step].max > 1 ? "pictures" : "picture"}</strong>)</i></label>
						<ImagePreview files={val} onDelete={removePic}/>
						{ !isUploading ?
							<div className={(val != null && val != undefined && data[step].max == val.length) ? "pic_upload disabled" : "pic_upload"} onClick={() => {scrollToBottom()}}>
								<input type="file"  id={data[step].id} accept="image/*" capture onChange={e => { processFiles(e); setHasChanged(true); /*scrollToBottom();*/}} multiple={data[step].max > 1} disabled={(val != undefined && data[step].max == val.length)}/>
							</div>:
							<div style={{textAlign: "center", marginTop: "4rem", marginBottom: "4rem"}}>
								<div className='uploading'>
									<div className='border'></div>
									<img alt={uploading} src={uploading}></img> 
								</div>
								<p style={{marginTop: "1rem", fontSize: "3rem", marginBottom: "0", fontWeight: "300"}}>Uploading...</p>
							</div>
						}
					</Fragment>
				)
			}
		} 
	}

	const POST_STEPS = async () => {
		// only calls POST if online
		/*let steps_to_update = [], corresponding_data = [];
		// gather all the data necessary for the upload
		for(let i = 0; i <= step; i++){
			if(!data[i].isUploaded && data[i].value != null && data[i].value != ""){
				steps_to_update.push(i); // the step we're updating
				data[i].isUploaded = true;
				if(data[i].type == "picture"){
					// MAKE SURE THAT ALL IMAGES ARE UPLOADED
					if(data[i].value.some(f => f instanceof File)){
						data[i].value = await imageUpload(data[i].value);
					}
				}
				corresponding_data.push(data[i]); // data with the step
			}
		}
		//console.log(steps_to_update)
		//console.log(corresponding_data)
		*/
		console.log({
			steps: [Number(step)],
			data: [data[step]]
		})

		let payload = {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				"Accept": "*/*",
				"Accept-Encoding": "gzip, deflate, br"
			},
			body: JSON.stringify({
				steps: [Number(step)],
				data: [data[step]]
			})
		}
		setIsUploading(true);
		scrollToBottom();
		try {
			let response = await fetch(system_url + project + "&system=" + system + "&list=" + list, payload).then(res => res);
			console.log("POST response", response.status)
			if(response.status == 200) {
				data[step].isUploaded = true;
				updateSystemData(system, data, list, step);
				scrollToBottom()
				updateUploadTime();
				return true;
			} else {
				data[step].isUploaded = false;
				updateSystemData(system, data, list, step);
				scrollToBottom()
				updateUploadTime();
				return true;
			}
		} catch (error) {
			alert(error)
			data[step].isUploaded = false;
			updateSystemData(system, data, list, step);
			return true;
		}
	}

	const generateBlob = async (file) => new Blob([ new Uint8Array(await file.arrayBuffer()) ], { type: file.type });

	const imageUpload = async (images) => {
		if(images.length > 0){
			let files_send = [];
			let num_new_files = 0; // if new files are in the array
			images.forEach((file, ind) => {
				// ind is for the original location in val
				if(file instanceof File){
					num_new_files++; // there are new files to be uploaded
					files_send.push({ ind: ind, name: file.name, type: file.type, lastModified: file.lastModified, isUploaded: false })
				}
			})
			if(num_new_files > 0){
				setIsUploading(true);
				scrollToBottom();
				let payload = {
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json; charset=UTF-8',
						"Accept": "*/*",
						"Accept-Encoding": "gzip, deflate, br"
					},
					body: JSON.stringify({ files: files_send })
				}
				let response_arr = [...images];
				//console.log("original response array", response_arr);
	
				try {
					let signed_urls = await fetch(signer_url, payload).then(response => response.json())
					//console.log(signed_urls)
					if(signed_urls.length > 0) {
						for (const [i, file] of signed_urls.entries()){
							//console.log([i, file])
							try {
								let blobbers = await generateBlob(images[files_send[i].ind]);
								const file_data = {
									...file.fields,
									"Content-Type": file.type,
									file: blobbers
								}
								const form_data = new FormData()
								for (const name in file_data){
									form_data.append(name, file_data[name])
								}
								
								try {
									await fetch(file.url, {
										method: 'POST',
										body: form_data
									})
									response_arr[files_send[i].ind] = {
										name: files_send[i]["name"],
										type: files_send[i]["type"],
										lastModified: files_send[i]["lastModified"],
										key: file_data["key"],
										isUploaded: true
									}
									if(i == num_new_files - 1){
										//console.log("response is ready")
										return response_arr;
									}
								} catch (e) {
									console.error(e);
									return false;
								}
							} catch (err) {
								console.error(err);
								return false;
							}
						}
					}
				} catch (error) {
					console.error(error);
					return false;
				}
			} else {
				return images;
			}
		}
	};

	const handleSubmitNext = (e) => {
		if (step <= data.length -1){
			if(data[step].type != "picture"){
				//routeChangeNext();
				postData(true);
				
				// ---- FOR TESTING PURPOSES ONLY ----
				/*data[step].value = val;
				if(comments && comments.length > 0){ data[step].comments = comments; }
				updateSystemData(system, data, list, step)*/
				// ---- END FOR TESTING PURPOSES ONLY ----
			} else {
				//routeChangeNext();
				if(hasChanged){
					// only upload if there's been a change in the image array
					if(isOnline){
						imageUpload(val).then(res => {
							if(Array.isArray(res)){
								console.log("new images to upload", res)
								setVal(res);
								postImageData(true, res)
							} else if(res) {
								console.log(res);
								//postImageData(true, val)
							} else {
								data[step].isUploaded = false;
							}
						})	
					} else {
						postImageData(true, val)
					}
				} else {
					routeChangeNext();
				}
			}
		} else {
			routeChangeNext();
		}
	}

	const postData = async (toNext) => {
		if((val.length > 0 && val != data[step].value) || (typeof comments != "undefined" && comments.length > 0 && comments != data[step].comments)){
			// only changes parent data if there's been a change to either the value or comments
			data[step].value = val; data[step].isUploaded = false;
			if(comments && comments.length > 0){ data[step].comments = comments; }
			if(isOnline){
				let ready = await POST_STEPS();
				(toNext && ready) ? routeChangeNext() : routeChangePrev(); // go to the next page or previous page
			} else {
				// FLAG THIS AS NOT UPLOADED AND SAVE TO STORAGE
				data[step].isUploaded = false;
				updateSystemData(system, data, list, step);
				toNext ? routeChangeNext() : routeChangePrev(); // go to the next page or previous page
			}
		} else {
			// if nothing's changed, just move on
			toNext ? routeChangeNext() : routeChangePrev(); // go to the next page or previous page
		}
	}

	const postImageData = async (toNext, img_arr) => {
		// function occurs as callback to fileupload, which needs to be online by definition
		console.log("passed img_arr", img_arr)
		if(img_arr.length > 0){
			// only changes parent data if there's been a change to either the value or comments
			data[step].value = img_arr; data[step].isUploaded = false;
			/*
			// --- FOR TESTING PURPOSES ONLY ---
			data[step].isUploaded = false;
			updateSystemData(system, data, list, step);
			console.log("saving to storage")
			toNext ? routeChangeNext() : routeChangePrev(); // go to the next page or previous page	
			// --- END TESTING SECTION ---
			*/
			if(isOnline){
				// only calls POST if online
				let ready = await POST_STEPS();
				(toNext && ready) ? routeChangeNext() : routeChangePrev(); // go to the next page or previous page
			} else {
				data[step].isUploaded = false;
				updateSystemData(system, data, list, step);
				console.log("saving to storage")
				toNext ? routeChangeNext() : routeChangePrev(); // go to the next page or previous page	
			}
		} else {
			// if nothing's changed, just move on
			toNext ? routeChangeNext() : routeChangePrev(); // go to the next page or previous page
		}
	}

	const handleSubmitPrev = (e) => {
		//postData(false);
		routeChangePrev();
	}

	const allUploaded = () => {
		let all_done = true;
		data.forEach(s => {
			if(s.value != null) {
				//console.log(all_done)
				all_done &= s.isUploaded
			}
		})
		return all_done;
	}

	return (
		<Container clss="step">
			<header>
				<Topbar></Topbar>
				<div style={status_style_background}>
					<p style={status_style}>{isOnline ? "Online" : "Offline"}</p>
				</div>
				<Row>
					<div style={{borderBottom:"black 2px solid", marginTop: "1rem", marginBottom: "1rem"}}></div>
				</Row>
				{ (isLoaded && !hasError) &&
					<Fragment>
						<div className="step_header">
							<Row>
								<Column width="3">
									<p className='subtitle_block'>Room #</p>
									<h2 className="subtitle_block"><strong>{roomNumber}</strong></h2>	
								</Column>
								<Column width="9">
									<h3 className="subtitle_block">
										<strong>{sku.split(",")[0]}</strong><br />{sku.split(",").slice(1, sku.split(",").length)}
									</h3>
								</Column>
							</Row>
						</div>
						<Row>
							<div style={{borderBottom:"black 2px solid", marginBottom: "1rem"}}></div>
						</Row>
					</Fragment>
				}
				{ (isLoaded && !hasError && step <= data.length -1) &&
					<Fragment>
						<Row>
							<p className="subtitle_block"><strong>Step {parseInt(step) + 1}</strong> of {data.length}</p>
						</Row>
						{ data[step].title &&
							<Row>
								<h2 className="title_block">{data[step].title}</h2>
							</Row>
						}
					</Fragment>
				}
			</header>
			<section>
				<div ref={contentTopRef} className="top"></div>
				{ (!isLoaded && !hasError) && <p className="content_block">Loading...</p> }
				{ (isLoaded && hasError) && <p className="content_block">Error!</p> }
				{ (isLoaded && !hasError && step <= data.length -1) &&
					<Fragment>
						{data[step].img && Images && 
							<Row>{<img className="portal_graphic" alt={`${sku}_${list}_${step}`} src={Images[data[step].img.split(".")[0]]}></img>}</Row>
						}
						<Row>
							<form className="form_fields">
								{data[step].label.split("\n").map((text, i) => { return (<label key={"l_" + i}>{text}</label>) })}
								{data[step].placeholder && <label style={{marginBottom: "0.5rem"}}><i>Example: {data[step].placeholder}</i></label>}
								{insertInputs()}
							</form>
						</Row>
					</Fragment>
				}
				{ (isLoaded && !hasError && step == data.length) &&
					<EndScreen list={list} system={system} allUploaded={allUploaded()}></EndScreen>
				}
				{ (isLoaded && isUploading && data[step].type != "picture") &&
					<Row>
						<div style={{textAlign: "center", marginTop: "4rem", marginBottom: "4rem"}}>
							<div className='uploading'>
								<div className='border'></div>
								<img alt={uploading} src={uploading}></img> 
							</div>
							<p style={{marginTop: "1rem", fontSize: "3rem", marginBottom: "0", fontWeight: "300"}}>Uploading...</p>
						</div>
					</Row>
				}
				<div ref={contentBottomRef} className="bottom"></div>
			</section>
			<footer>
				<Row>
					{ (isLoaded && !hasError) &&
						<div className="button_accordion">
							<Column width="5">
								<button className="prev_button" onClick={handleSubmitPrev}>Back</button>
							</Column>
							<Column width="5" offset="2">
								<button className={"next_button" + (isUploading ? " uploading" : "")} disabled={ //false 
									 step != data.length && (
										isUploading || 
										(data[step].type != "check" && val == "") ||
										(data[step].type == "check" && val == "") ||
										(data[step].type == "check" && val == "No" && comments == "") || 
										(val instanceof String && ("regex" in data[step] && !val.match(data[step].regex)))
									)
								} onClick={handleSubmitNext}>Next</button>
							</Column>
						</div>
					}
				</Row>
			</footer>
		</Container>
	);
}

export default StepPage;
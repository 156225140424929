import React, { useCallback, useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import isReachable from 'is-reachable';
import localforage from "localforage";

import StepPage from './pages/step_page.jsx'
import ProjectPage from './pages/project_page.jsx'
import DisasterPage from './pages/disaster_page.jsx'
import ImagesArr from './images.js'

//export const DataContext = React.createContext();
//export const OnlineContext = React.createContext();
//export const ProjectContext = React.createContext();
//export const UploadingContext = React.createContext();
//export const ImagesContext = React.createContext();
export const AppContext = React.createContext();

const URL = "google.com:443";
const CHECK_TIME = 1000; // ONE SECOND CHECK CONNECTIVITY TIME
const BATCH_UPDATE_URL = "https://n42rss2i5d.execute-api.us-east-1.amazonaws.com/Prod/project?project=";
const SIGNER_URL = "https://n42rss2i5d.execute-api.us-east-1.amazonaws.com/Prod/system/files";
const PROJECT_URL = "https://n42rss2i5d.execute-api.us-east-1.amazonaws.com/Prod/project/?project=";

const App = () => {
	const [data, setData] = useState([]);
	const [isOnline, setIsOnline] = useState(false);
	const [project, setProject] = useState("");
	const [needsUploading, setNeedsUploading] = useState(false);
	const [systemsModified, setSystemsModified] = useState([]);
	const [Images, setImages] = useState();

	let last_data_update = null; // LAST TIME DATA WAS CHANGED
	let last_data_upload = null; // LAST TIME DATA WAS UPLOADED

	//let isUploading = false; // --- FOR TESTING PURPOSES ONLY --- 

	const setProjectID = async (project_id) => {
		if (project !== project_id && project_id.length > 0) {
			//console.log("project passed is ", project_id)
			setProject(project_id);
			// new project
			try {
				await localforage.setItem('project', project_id);
			} catch (err){
				console.log(err)
			}
			// removes stored data
			await localforage.removeItem('data');
			await localforage.removeItem('last_data_update');
			await localforage.removeItem('last_data_upload');
		}
	}

	const addToSysMod = (sys_id) => {
		if(systemsModified.length == 0){
			let sys_mod = [];
			sys_mod.push(sys_id);
			setSystemsModified(sys_mod);
			//localforage.setItem('systems_modified', sys_mod);
		} else if(systemsModified.indexOf(sys_id) === -1){
			let sys_mod = [...systemsModified];
			sys_mod.push(sys_id);
			setSystemsModified(sys_mod);
			//localforage.setItem('systems_modified', sys_mod);
		}
	}

	// https://stackoverflow.com/questions/60945095/change-state-dynamically-based-on-the-external-internet-connectivity-react-of
	/*const setOnline = async (online) => {
		if(online) {
			setIsOnline(true);
			// if has become online
			localforage.setItem('isOnline', true);
			// check elapsed time between last time the data was changed and the last time the data was uploaded
			
			last_data_update = await localforage.getItem('last_data_update');
			last_data_upload = await localforage.getItem('last_data_upload');

			console.log("\tlast_data_update", last_data_update);
			console.log("\tlast_data_upload", last_data_upload);
			console.log("\tsystems_modified", systemsModified);
			console.log("\tdata", data);

			if (new Date(last_data_update) - new Date(last_data_upload) > 0 && systemsModified.length > 0){
				console.log("need to upload");
				console.log(last_data_update - last_data_upload);
				// upload data to AWS
				uploadEntirePayload();
			}
		} else {
			setIsOnline(false);
			// if offline
			localforage.setItem('isOnline', false);
			//console.log("setting isOnline")
			//isOnline = JSON.parse(localforage.getItem('isOnline'));
		}
	}*/

	const setCookieUpdate = async (date) => {
		//console.log("setting update date")
		await localforage.setItem('last_data_update', date)
	}

	const setCookieUpload = async (date) => {
		//console.log("setting upload date")
		await localforage.setItem('last_data_upload', date)
	}

	const setCookieData = async (d, date = null) => {
		console.log("APP.JS setCookieData: ", d, date)
		setData(d);
		await localforage.setItem('data', d);
		if(!date){
			date = new Date();
		}
		await setCookieUpdate(date);
	}
	//system.system, system.preflight[i], "preflight", i
	const sysUpdate = (id, val, list, step) => {
		let proj_data = [...data];
		let index;
		
		proj_data.find((sys, ind) => {
			if (sys.system == id){
				index = ind;
				return true
			}
		})
		
		// modifies the data
		proj_data[index][list][step].value = val;

		// updates data state
		setData(proj_data);
	}
	
	const projCallback = useCallback(async (d, project = "") => {
		console.log("APP.JS projCallback params: ", "d = ", d, "project = ",project)
		// should only be used to get data from AWS if there isn't any already loaded
		if(project && project.length > 0 ){ await setProjectID(project); }
		/*if(systems_modified == null){
			setSystemsModified([])
			await localforage.setItem('systems_modified', []);
		}
		if(isOnline == null){
			await localforage.setItem('isOnline', true);
		}*/
		if(last_data_upload == null && d != null){
			let date = new Date();
			await setCookieUpload(date);
			await setCookieData(d, date);
		} else if(d != null){
			await setCookieData(d);
		}
	}, [])
	
	const sysCallback = useCallback(async (id, d, list, step) => {
		// this should ONLY be used to update a system's data
		console.log("APP.JS sysCallback params: ", id, d, list, step)
		let proj_data = [...data];
		let system, index;
		
		proj_data.find((sys, ind) => {
			if (sys.system == id){
				system = proj_data[ind];
				index = ind;
				return true
			}
		})

		if(!isOnline && !needsUploading){
			setNeedsUploading(true);
		}

		system[list][step] = d[step];
		if(d[step].type == "picture"){ 
			if(d[step].value.some(pic => pic instanceof File)){
				d[step].value = await saveImageForLater(d[step].value, system.system, list, step);
			}
		}
		proj_data[index][list][step] = d[step];
		addToSysMod(system.system);
		//console.log("step " + step + " in system " + id + " " + list + " has changed")

		// --- FOR TESTING PURPOSES ONLY --- 
		//if(!isUploading) { uploadEntirePayload(); }
		// --- END OF FOR TESTING PURPOSES ONLY --- 

		setCookieData(proj_data);
	})

	const uploadEntirePayload = async () => {
		console.log("APP.JS: UPLOADING ENTIRE PAYLOAD")
		let systems_to_update = [];
		//isUploading = true; // SO NO INFINITE LOOPING
		//setSystemsModified(await localforage.getItem('systems_modified'))
		//console.log("systems_modified", systems_modified)
		let d = await localforage.getItem('data')
		if (d != null){
			d.forEach(system => {
				// need to go through all systems and check if they've been modified
				if(systemsModified.indexOf(system.system) != -1){
					let sys = Object.assign({}, system);
					systems_to_update.push(sys);
				}
			})	
		}
		if(systems_to_update.length > 0){
			console.log("batch uploading entire payload")
			let steps_pre_uploaded = [], steps_post_uploaded = [], uploadingImgs = [];
			for (const system of systems_to_update){
				for (const [i, step] of system.preflight.entries()){
					// go through preflight and check if there's any pics that need to be uploaded
					if(step.type == "picture" && step.value != null && step.value.length > 0){
						// ONLY CALL uploadImages IF THERE'S ANYTHING THAT NEEDS TO BE UPLOADED
						if(step.value.some(pic => pic instanceof File) || step.value.some(pic => pic.blob != null)){
							let res = await uploadImages(step.value);
							uploadingImgs.push(res);
							steps_pre_uploaded.push(i);
							system.preflight[i].value = res;
							sysUpdate(system.system, system.preflight[i].value, "preflight", i);
						}
					}
				}
				for (const [i, step] of system.postinstall.entries()){
					// go postinstall through and check if there's any pics that need to be uploaded
					if(step.type == "picture" && step.value != null && step.value.length > 0){
						// ONLY CALL uploadImages IF THERE'S ANYTHING THAT NEEDS TO BE UPLOADED
						if(step.value.some(pic => pic instanceof File) || step.value.some(pic => pic.blob != null)){
							let res = await uploadImages(step.value);
							uploadingImgs.push(res);
							steps_post_uploaded.push(i);
							system.postinstall[i].value = res;
							sysUpdate(system.system, system.postinstall[i].value, "postinstall", i);
						}
					}
				}
			}
			//setUploadedImages(uploadingImgs);
			// upload data to lambda
			/*console.log(systems_to_update);
			steps_pre_uploaded.forEach(st => {
				console.log(systems_to_update[0].preflight[st]);
			})
			steps_post_uploaded.forEach(st => {
				console.log(systems_to_update[0].preflight[st]);
			})*/
			let payload = {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json; charset=UTF-8',
					"Accept": "*/*",
					"Accept-Encoding": "gzip, deflate, br"
				},
				body: JSON.stringify({
					payload: systems_to_update
				})
			}
			console.log(payload)
			const pr = await localforage.getItem("project");
			console.log("Project for fetch ", pr)
			console.log("State Project for fetch ", project)
			if(pr != ""){
				// POSTs the update
				let BATCH_UP_URL = BATCH_UPDATE_URL + pr;
				BATCH_UP_URL = BATCH_UP_URL.replace(/"/g, "");
				BATCH_UP_URL = BATCH_UP_URL.replace(/\s/g, "%20");
				console.log(BATCH_UP_URL);
				await fetch(BATCH_UPDATE_URL + pr, payload)
				// GETs the project data again and updates localforage
				console.log(PROJECT_URL + pr);
				let new_full_batch = await (await fetch(PROJECT_URL + pr)).json();
				projCallback(new_full_batch);
				setNeedsUploading(false);
			}
			
			setSystemsModified([])
			await localforage.setItem('systems_modified', []);
			//isUploading = false;
		} else {
			return;
		}
	}

	const generateBlob = async (file) => new Blob([ new Uint8Array(await file.arrayBuffer()) ], { type: file.type });
	//https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
	const generateBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});

	const saveImageForLater = async (images, sys_id, list, step) => {
		console.log("images passed ", images)
		let files_save = [];
		for (const [ind, file] of images.entries()){
			// ind is for the original location in images
			if(file instanceof File){
				const base64 = await generateBase64(file);
				/*const localPath = sys_id + '_' + list + '_' + step + '_img' + ind;
				await localforage.setItem(localPath, base64);*/
				files_save.push({ ind: ind, name: file.name, type: file.type, lastModified: file.lastModified, isUploaded: false, blob: base64 });
			} else {
				files_save.push(file);
			}
		}
		console.log("files_save generated", files_save)
		return files_save;
	}

	const uploadImages = async (images) => {
		if(images.length > 0){
			//console.log(images);
			let files_send = [];
			let num_new_files = 0; // if new files are in the array
			images.forEach((file, ind) => {
				// ind is for the original location in images
				if(file instanceof File){
					num_new_files++; // there are new files to be uploaded
					files_send.push({ ind: ind, name: file.name, type: file.type, lastModified: file.lastModified, isUploaded: false })
				} else if(file.blob){
					//console.log("there is a blob")
					num_new_files++; // if the files have already been processed
					files_send.push({ ind: ind, name: file.name, type: file.type, lastModified: file.lastModified, isUploaded: false })
				}
			})
			if(num_new_files > 0){
				// if there are new files to send
				let payload = {
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json; charset=UTF-8',
						"Accept": "*/*",
						"Accept-Encoding": "gzip, deflate, br"
					},
					body: JSON.stringify({ files: files_send })
				}
				let response_arr = [...images];
	
				try {
					let signed_urls = await fetch(SIGNER_URL, payload).then(response => response.json())
					if(signed_urls.length > 0) {
						console.log(signed_urls)
						for (const [i, file] of signed_urls.entries()){
							try {
								let blobbers;
								if(images[files_send[i].ind] instanceof File){
									// convert File object to Blob
									blobbers = await generateBlob(images[files_send[i].ind]);
								} else if(images[files_send[i].ind].blob){
									// convert base64 to Blob
									blobbers = await fetch(images[files_send[i].ind].blob).then(res => res.blob());
								}
								const file_data = {
									...file.fields,
									"Content-Type": file.type,
									file: blobbers
								}
								const form_data = new FormData()
								for (const name in file_data){
									form_data.append(name, file_data[name])
								}
								try {
									await fetch(file.url, {
										method: 'POST',
										body: form_data
									})
									response_arr[files_send[i].ind] = {
										name: files_send[i]["name"],
										type: files_send[i]["type"],
										lastModified: files_send[i]["lastModified"],
										key: file_data["key"],
										isUploaded: true
									}
									if(i == num_new_files - 1){
										console.log("response is ready")
										return response_arr;
									}
								} catch (e) {
									throw e;
								}
							} catch (err) {
								throw err;
							}
						}
					}
				} catch (error) {
					throw error;
				}
			} else {
				return images;
			}
		}
	};

	const setStepImages = async () => {
		try {
			let imgs = await localforage.getItem("Images")
			if(imgs != null){
				//console.log("images already there", imgs)
				setImages(imgs);
			} else {
				let images = {};
				for (const [key, image] of Object.entries(ImagesArr)) {
					images[key] = await blobToBase64(await fetch(image).then(res => res.blob()));
				}
				let imgs = await localforage.setItem("Images", images);
				setImages(images)
				//console.log("setting images", imgs)
			}
		} catch {
			let images = {};
			for (const [key, image] of Object.entries(ImagesArr)){
				images[key] = await blobToBase64(await fetch(image).then(res => res.blob()));
			}
			let imgs = await localforage.setItem("Images", images);
			setImages(imgs);
			//console.log("setting images")
		}
	}

	const blobToBase64 = (blob) => {
		return new Promise((resolve, _) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.readAsDataURL(blob);
		});
	}

	useEffect(() => {
		const changeOnline = async () => {
			localforage.setItem('isOnline', isOnline);
			console.log("isOnline has changed")
			if(isOnline){
				last_data_update = await localforage.getItem('last_data_update');
				last_data_upload = await localforage.getItem('last_data_upload');
	
				console.log("\tlast_data_update", last_data_update);
				console.log("\tlast_data_upload", last_data_upload);
				console.log("\tsystems_modified", systemsModified);
				console.log("\tdata", data);
	
				if (new Date(last_data_update) - new Date(last_data_upload) > 0 && systemsModified.length > 0){
					console.log("need to upload");
					console.log(last_data_update - last_data_upload);
					// upload data to AWS
					uploadEntirePayload();
				}
			}
		}
		changeOnline();
	}, [isOnline])

	useEffect(() => {
		const fetchData = async () => {
			console.log("APP.JS USEEFFECT CALLED")
			await setStepImages()
			isReachable(URL).then(o => {
				setIsOnline(o);
			});
		}
		fetchData();
		setInterval(async () => {
			let o = await isReachable(URL);
			try {
				if(o != await localforage.getItem('isOnline')){
					setIsOnline(o);
					//setOnline(o).then(o);
				}
				if(data.length == 0 && project.length > 0){
					let stored_data = await localforage.getItem('data');
					setData(stored_data);
				}
			} catch (e) {
				//console.log(e)
				if(o != await localforage.getItem('isOnline')){
					setIsOnline(false);
					//setOnline(false).then(false);
				}
			}
		}, CHECK_TIME);
	}, [])

	return (
		<BrowserRouter>
			<AppContext.Provider value={[data, isOnline, project, needsUploading, Images, systemsModified]}>
				<Routes>
					<Route exact path={`/`} element={<h1>Please enter a project or system number in the address bar</h1>}/>
					<Route exact path={`/project/:project`} element={<ProjectPage parentCallback={projCallback}/>}/>
					<Route exact path={`/system/:project/:list/:system/:step`} element={<StepPage parentUploadTimeCallback={setCookieUpload} parentProjCallback={projCallback} parentSysCallback={sysCallback}/>}/>
					<Route exact path={`/disaster/:project/:system`} element={<DisasterPage/>}/>
				</Routes>
			</AppContext.Provider>
		</BrowserRouter>
	);
}

export default App;
import closet_mode from "./assets/images/closet_mode.svg";
import step_19_CBS from "./assets/images/step_19_CBS.jpg";
import step_4_PC from "./assets/images/step_4_PC.svg";
import living_room_mode from "./assets/images/living_room_mode.svg";
import step_19_PC from "./assets/images/step_19_PC.jpg";
import step_4_PO from "./assets/images/step_4_PO.svg";
import step_10_CBS from "./assets/images/step_10_CBS.jpg";
import step_19_PO from "./assets/images/step_19_PO.jpg";
import step_4_PSL from "./assets/images/step_4_PSL.svg";
import step_10_CBT from "./assets/images/step_10_CBT.jpg";
import step_19_PSP from "./assets/images/step_19_PSP.jpg";
import step_4_PSP from "./assets/images/step_4_PSP.svg";
import step_10_PSL from "./assets/images/step_10_PSL.jpg";
import step_1_CBS from "./assets/images/step_1_CBS.jpg";
import step_5_CBS from "./assets/images/step_5_CBS.svg";
import step_10_PSP from "./assets/images/step_10_PSP.jpg";
import step_1_CBT from "./assets/images/step_1_CBT.jpg";
import step_5_CBT from "./assets/images/step_5_CBT.svg";
import step_11_CBS from "./assets/images/step_11_CBS.jpg";
import step_1_PC from "./assets/images/step_1_PC.jpg";
import step_5_PC from "./assets/images/step_5_PC.svg";
import step_11_CBT from "./assets/images/step_11_CBT.jpg";
import step_1_PO from "./assets/images/step_1_PO.jpg";
import step_5_PO from "./assets/images/step_5_PO.svg";
import step_11_PSL from "./assets/images/step_11_PSL.jpg";
import step_1_PSL from "./assets/images/step_1_PSL.jpg";
import step_5_PSL from "./assets/images/step_5_PSL.svg";
import step_11_PSP from "./assets/images/step_11_PSP.jpg";
import step_1_PSP from "./assets/images/step_1_PSP.jpg";
import step_5_PSP from "./assets/images/step_5_PSP.svg";
import step_12_PSL from "./assets/images/step_12_PSL.jpg";
import step_1_preflight from "./assets/images/step_1_preflight.jpg";
import step_5_preflight from "./assets/images/step_5_preflight.jpg";
import step_12_PSP from "./assets/images/step_12_PSP.jpg";
import step_20_CBT from "./assets/images/step_20_CBT.jpg";
import step_6_CBS from "./assets/images/step_6_CBS.jpg";
import step_13_PC from "./assets/images/step_13_PC.jpg";
import step_20_PO from "./assets/images/step_20_PO.jpg";
import step_6_CBT from "./assets/images/step_6_CBT.jpg";
import step_13_PO from "./assets/images/step_13_PO.jpg";
import step_20_PSP from "./assets/images/step_20_PSP.jpg";
import step_6_PC from "./assets/images/step_6_PC.jpg";
import step_13_PSP from "./assets/images/step_13_PSP.jpg";
import step_21_CBS from "./assets/images/step_21_CBS.jpg";
import step_6_PO from "./assets/images/step_6_PO.jpg";
import step_14_PC from "./assets/images/step_14_PC.jpg";
import step_21_PSL from "./assets/images/step_21_PSL.jpg";
import step_6_PSL from "./assets/images/step_6_PSL.jpg";
import step_14_PO from "./assets/images/step_14_PO.jpg";
import step_23_PSP from "./assets/images/step_23_PSP.jpg";
import step_6_PSP from "./assets/images/step_6_PSP.jpg";
import step_26_PC from "./assets/images/step_26_PC.jpg";
import step_6_preflight from "./assets/images/step_6_preflight.jpg";
import step_15_CBT from "./assets/images/step_15_CBT.jpg";
import step_26_PO from "./assets/images/step_26_PO.jpg";
import step_7_CBS from "./assets/images/step_7_CBS.jpg";
import step_15_PC from "./assets/images/step_15_PC.jpg";
import step_2_CBS from "./assets/images/step_2_CBS.jpg";
import step_7_PC from "./assets/images/step_7_PC.jpg";
import step_15_PO from "./assets/images/step_15_PO.jpg";
import step_2_CBT from "./assets/images/step_2_CBT.jpg";
import step_7_PO from "./assets/images/step_7_PO.jpg";
import step_15_PSL from "./assets/images/step_15_PSL.jpg";
import step_2_PC from "./assets/images/step_2_PC.jpg";
import step_7_PSL from "./assets/images/step_7_PSL.jpg";
import step_16_CBS from "./assets/images/step_16_CBS.jpg";
import step_2_PO from "./assets/images/step_2_PO.jpg";
import step_7_PSP from "./assets/images/step_7_PSP.jpg";
import step_16_CBT from "./assets/images/step_16_CBT.jpg";
import step_2_PSL from "./assets/images/step_2_PSL.jpg";
import step_7_preflight from "./assets/images/step_7_preflight.jpg";
import step_16_PC from "./assets/images/step_16_PC.jpg";
import step_2_PSP from "./assets/images/step_2_PSP.jpg";
import step_8_CBS from "./assets/images/step_8_CBS.jpg";
import step_16_PO from "./assets/images/step_16_PO.jpg";
import step_2_preflight from "./assets/images/step_2_preflight.jpg";
import step_8_CBT from "./assets/images/step_8_CBT.jpg";
import step_16_PSL from "./assets/images/step_16_PSL.jpg";
import step_31_PSL from "./assets/images/step_31_PSL.jpg";
import step_8_PC from "./assets/images/step_8_PC.jpg";
import step_16_PSP from "./assets/images/step_16_PSP.jpg";
import step_31_PSP from "./assets/images/step_31_PSP.jpg";
import step_8_PSL from "./assets/images/step_8_PSL.jpg";
import step_17_CBS from "./assets/images/step_17_CBS.jpg";
import step_3_CBS from "./assets/images/step_3_CBS.jpg";
import step_8_PSP from "./assets/images/step_8_PSP.jpg";
import step_17_CBT from "./assets/images/step_17_CBT.jpg";
import step_3_CBT from "./assets/images/step_3_CBT.jpg";
import step_9_CBS from "./assets/images/step_9_CBS.jpg";
import step_17_PSL from "./assets/images/step_17_PSL.jpg";
import step_3_PC from "./assets/images/step_3_PC.jpg";
import step_9_CBT from "./assets/images/step_9_CBT.jpg";
import step_17_PSP from "./assets/images/step_17_PSP.jpg";
import step_3_PO from "./assets/images/step_3_PO.jpg";
import step_9_PC from "./assets/images/step_9_PC.jpg";
import step_18_CBS from "./assets/images/step_18_CBS.jpg";
import step_3_PSL from "./assets/images/step_3_PSL.jpg";
import step_9_PO from "./assets/images/step_9_PO.jpg";
import step_18_CBT from "./assets/images/step_18_CBT.jpg";
import step_3_PSP from "./assets/images/step_3_PSP.jpg";
import step_9_PSL from "./assets/images/step_9_PSL.jpg";
import step_18_PC from "./assets/images/step_18_PC.jpg";
import step_3_preflight from "./assets/images/step_3_preflight.jpg";
import step_9_PSP from "./assets/images/step_9_PSP.jpg";
import step_18_PSL from "./assets/images/step_18_PSL.jpg";
import step_4_CBS from "./assets/images/step_4_CBS.svg";
import step_18_PSP from "./assets/images/step_18_PSP.jpg";
import step_4_CBT from "./assets/images/step_4_CBT.svg"

import cloud_bed_sofa from "./assets/icons/cloud_bed_sofa.svg"
import cloud_bed_table from "./assets/icons/cloud_bed_table.svg"
import pocket_closet from "./assets/icons/pocket_closet.svg"
import pocket_office from "./assets/icons/pocket_office.svg"
import pocket_studio_lite from "./assets/icons/pocket_studio_lite.svg"
import pocket_studio_plus from "./assets/icons/pocket_studio_plus.svg"

const ImagesArr = {
	"closet_mode": closet_mode,
	"step_19_CBS": step_19_CBS,
	"step_4_PC": step_4_PC,
	"living_room_mode": living_room_mode,
	"step_19_PC": step_19_PC,
	"step_4_PO": step_4_PO,
	"step_10_CBS": step_10_CBS,
	"step_19_PO": step_19_PO,
	"step_4_PSL": step_4_PSL,
	"step_10_CBT": step_10_CBT,
	"step_19_PSP": step_19_PSP,
	"step_4_PSP": step_4_PSP,
	"step_10_PSL": step_10_PSL,
	"step_1_CBS": step_1_CBS,
	"step_5_CBS": step_5_CBS,
	"step_10_PSP": step_10_PSP,
	"step_1_CBT": step_1_CBT,
	"step_5_CBT": step_5_CBT,
	"step_11_CBS": step_11_CBS,
	"step_1_PC": step_1_PC,
	"step_5_PC": step_5_PC,
	"step_11_CBT": step_11_CBT,
	"step_1_PO": step_1_PO,
	"step_5_PO": step_5_PO,
	"step_11_PSL": step_11_PSL,
	"step_1_PSL": step_1_PSL,
	"step_5_PSL": step_5_PSL,
	"step_11_PSP": step_11_PSP,
	"step_1_PSP": step_1_PSP,
	"step_5_PSP": step_5_PSP,
	"step_12_PSL": step_12_PSL,
	"step_1_preflight": step_1_preflight,
	"step_5_preflight": step_5_preflight,
	"step_12_PSP": step_12_PSP,
	"step_20_CBT": step_20_CBT,
	"step_6_CBS": step_6_CBS,
	"step_13_PC": step_13_PC,
	"step_20_PO": step_20_PO,
	"step_6_CBT": step_6_CBT,
	"step_13_PO": step_13_PO,
	"step_20_PSP": step_20_PSP,
	"step_6_PC": step_6_PC,
	"step_13_PSP": step_13_PSP,
	"step_21_CBS": step_21_CBS,
	"step_6_PO": step_6_PO,
	"step_14_PC": step_14_PC,
	"step_21_PSL": step_21_PSL,
	"step_6_PSL": step_6_PSL,
	"step_14_PO": step_14_PO,
	"step_23_PSP": step_23_PSP,
	"step_6_PSP": step_6_PSP,
	"step_26_PC": step_26_PC,
	"step_6_preflight": step_6_preflight,
	"step_15_CBT": step_15_CBT,
	"step_26_PO": step_26_PO,
	"step_7_CBS": step_7_CBS,
	"step_15_PC": step_15_PC,
	"step_2_CBS": step_2_CBS,
	"step_7_PC": step_7_PC,
	"step_15_PO": step_15_PO,
	"step_2_CBT": step_2_CBT,
	"step_7_PO": step_7_PO,
	"step_15_PSL": step_15_PSL,
	"step_2_PC": step_2_PC,
	"step_7_PSL": step_7_PSL,
	"step_16_CBS": step_16_CBS,
	"step_2_PO": step_2_PO,
	"step_7_PSP": step_7_PSP,
	"step_16_CBT": step_16_CBT,
	"step_2_PSL": step_2_PSL,
	"step_7_preflight": step_7_preflight,
	"step_16_PC": step_16_PC,
	"step_2_PSP": step_2_PSP,
	"step_8_CBS": step_8_CBS,
	"step_16_PO": step_16_PO,
	"step_2_preflight": step_2_preflight,
	"step_8_CBT": step_8_CBT,
	"step_16_PSL": step_16_PSL,
	"step_31_PSL": step_31_PSL,
	"step_8_PC": step_8_PC,
	"step_16_PSP": step_16_PSP,
	"step_31_PSP": step_31_PSP,
	"step_8_PSL": step_8_PSL,
	"step_17_CBS": step_17_CBS,
	"step_3_CBS": step_3_CBS,
	"step_8_PSP": step_8_PSP,
	"step_17_CBT": step_17_CBT,
	"step_3_CBT": step_3_CBT,
	"step_9_CBS": step_9_CBS,
	"step_17_PSL": step_17_PSL,
	"step_3_PC": step_3_PC,
	"step_9_CBT": step_9_CBT,
	"step_17_PSP": step_17_PSP,
	"step_3_PO": step_3_PO,
	"step_9_PC": step_9_PC,
	"step_18_CBS": step_18_CBS,
	"step_3_PSL": step_3_PSL,
	"step_9_PO": step_9_PO,
	"step_18_CBT": step_18_CBT,
	"step_3_PSP": step_3_PSP,
	"step_9_PSL": step_9_PSL,
	"step_18_PC": step_18_PC,
	"step_3_preflight": step_3_preflight,
	"step_9_PSP": step_9_PSP,
	"step_18_PSL": step_18_PSL,
	"step_4_CBS": step_4_CBS,
	"step_18_PSP": step_18_PSP,
	"step_4_CBT": step_4_CBT,
	"cloud_bed_sofa": cloud_bed_sofa,
	"cloud_bed_table": cloud_bed_table,
	"pocket_closet": pocket_closet,
	"pocket_office": pocket_office,
	"pocket_studio_lite": pocket_studio_lite,
	"pocket_studio_plus": pocket_studio_plus,
};

export default ImagesArr
import React, { useState, useEffect, Fragment, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import lottie from 'lottie-web'
import Collapsible from 'react-collapsible'

import { AppContext } from '../app.jsx'

import { Container, Row, Column } from '../components/skeleton.jsx'
import Topbar from '../components/topbar.jsx'

import logo_animation from "../assets/animations/ori folding logo.json"

const url = "https://n42rss2i5d.execute-api.us-east-1.amazonaws.com/Prod/project/?project=";

const num_pre_optional = 1; // NUMBER OF STEPS ON THE PREFLIGHT THAT ARE OPTIONAL

const ProjectPage = (props) => {
	const { project } = useParams();
	const [data, isOnline, passed_proj, needsUploading, Images, systems_modified] = useContext(AppContext);

	const updateProjectData = props.parentCallback;

	const [nested, setNested] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
    const [hasError, setHasError] = useState(false);

	const status_style = {
		fontSize: "3rem",
		textAlign: "left",
		fontWeight: 600,
		marginLeft: "1rem",
		marginBottom: "0%",
		color: needsUploading ? (isOnline ? "#222222" : "white") : "#222222"
	}

	const status_style_background = {
		width: "100%",
		height: "4rem",
		backgroundColor: needsUploading ? (isOnline ? "#E6CB6A" : "#DA9D8C") : "#95CE95"
		// YELLOW // RED // GREEN 
	}

	const img_upload_container = {
		width: "100%",
		height: "4rem",
		display: "table",
		borderCollapse: "collapse"
	}

	useEffect(() => {
		document.title = `Ori | Punchlist - ${project}`
	}, [])

	useEffect(() => {
		if((data == null || data.length == 0 || passed_proj !== project) && isOnline && systems_modified.length == 0){
			console.log("PROJECT_PAGE.JS: waiting for data from online")
			fetch(url + project)
				.then(res => res.json())
				.catch((err) => {
					updateProjectData(null, project);
					setHasError(true)
				})
				.then((result) => {
					updateProjectData(result, project);
				});
		} else if(Images != null && data.length > 0){
			console.log("PROJECT_PAGE.JS: got data from APP.JS")
			setIsLoaded(true);
			let nest = data.reduce((a, b) => {
				a[b.room] = a[b.room] || [];
				a[b.room].push(b)
				return a
			}, {})
			console.log("\tSetting Nested")
			setNested(nest)
		}
		if(!isLoaded && document.querySelector("#logo").childNodes.length == 0){
			lottie.loadAnimation({
				container: document.querySelector("#logo"),
				animationData: logo_animation,
				renderer: 'svg',
			})
		}
	}, [data, project, Images, isOnline])

	const setSysGraphic = (system_id, sku) => {
		switch(system_id.substring(0,2)){
			case "PS":
				return sku.includes("Lite") ? Images.pocket_studio_lite : Images.pocket_studio_plus
			case "CB":{
				return sku.includes("Table") ? Images.cloud_bed_table : Images.cloud_bed_sofa
			}
			case "PC":
				return Images.pocket_closet
			case "PO":
				return Images.pocket_office
		}
	}

	const setRooms = () => {
		let room_arr = [];
		console.log(Object.entries(nested).length);
		for (const [room, systems] of Object.entries(nested)){
			let individual_room = [];
			let room_indicators = [];
			systems.forEach(sys => {
				//let completed_pre = 0, completed_post = 0;
				let completed_pre = 0, completed_post = true;

				sys.preflight.forEach(step => {
					if (step.value != null && step.value != undefined) completed_pre++;
					//completed_pre = completed_pre && (step.value != null || step.value != undefined);
				})
				sys.postinstall.forEach(step => {
					completed_post = completed_post && (step.value != null || step.value != undefined);
				})
				individual_room.push(
					<Row key={sys.system + "_img"}>
						<Column width="8">
							<img style={{width: "100%"}} src={setSysGraphic(sys.system, sys.sku)} alt={sys.sku}></img>
						</Column>
					</Row>
				)
				individual_room.push(
					<Fragment key={sys.system + "_row"}>
						<Row>
							<Column width="6">
								<h3 className="subtitle_block">
									<strong>{sys.sku.split(",")[0]}</strong><br />{sys.sku.split(",").slice(1, sys.sku.split(",").length)}
								</h3>
							</Column>
							<Column width="3">
								<Link to={"/system/" + project + "/preflight/" + sys.system + "/0"}><button className={completed_pre >= sys.preflight.length - num_pre_optional ? "list_block completed" : "list_block"}>Pre-Install</button></Link>
							</Column>
							<Column width="3">
								<Link to={"/system/" + project + "/postinstall/" + sys.system + "/0"}><button className={completed_post ? "list_block completed" : "list_block"} disabled={false/*!completed_pre*.*/}>Post-Install</button></Link>
							</Column>
						</Row>
						<Row>
							<Column width="6">
								<Link to={`/disaster/${project}/${sys.system}`}><button className="list_block">Did something go wrong?</button></Link>
							</Column>
						</Row>
					</Fragment>
					)
				let ind_style;
				if((completed_pre >= sys.preflight.length - num_pre_optional) && completed_post){
					ind_style = "room_i"
				} else if(completed_pre >= sys.preflight.length - num_pre_optional) {
					ind_style = "room_i incomplete"
				} else {
					ind_style = "room_i untouched"
				}
				room_indicators.push(<div key={sys.system + "_indicator"} className={ind_style}></div>)
			})
			if(Object.entries(nested).length > 1){
				// IF THERE'S MORE THAN ONE ROOM, PUT EACH ROOM INTO COLLAPSIBLE ROWS
				room_arr.push(
					<Collapsible trigger={
						<Row>
							<Column width="3">
								<h2 className='subtitle_block'><strong>{room}</strong></h2>
							</Column>
							<Column width="9"><div className="room_i_container">{room_indicators}</div></Column>
						</Row>
					} key={"room_" + room}>{individual_room}</Collapsible>
				)
				room_arr.push(
					<Row key={"divider_" + room}>
						<div style={{borderBottom:"black 1px dashed", marginBottom: "2rem"}}></div>
					</Row>
				)
			} else {
				// IF THERE'S ONLY ONE ROOM, PUT IT INTO A NON-COLLAPSIBLE VIEW
				room_arr = [
					<Row>
						<div className="room_i_container">{room_indicators}</div>
					</Row>
				].concat(individual_room);
			}
		}
		return room_arr
	}

	const setUploadBar = () => {
		let indicators = [];
		systems_modified.forEach(id => {
			let system = data.find(s => s.system == id);
			system.postinstall.forEach(stp => {
				if(stp.type == "picture"){
					if(stp.value != null){
						stp.value.forEach(img => {
							const img_upload_indicator = {
								display: "table-cell",
								backgroundColor: img.key ? "#95CE95" : "lightgray",
								border: "0.5rem #F9F6F0 solid"
							}
							indicators.push(<div style={img_upload_indicator}></div>)
						})
					}
				}
			})
		})
		return indicators;
	}

	return (
		<Container>
			<Topbar></Topbar>
			<div style={status_style_background}>
				<p style={status_style}>{needsUploading ? (isOnline ? "Syncing Data Now... Don't Close or Disconnect" : "Not Synced - Connect to Internet") : "Data Synced"}</p> 
			</div>
			{needsUploading &&
				<div style={img_upload_container}>{setUploadBar()}</div>
			}
			<Row>
				<div style={{borderBottom:"black 2px solid", marginTop: "1rem", marginBottom: "1rem"}}></div>
			</Row>
			{(!isLoaded && !hasError) &&
				<Fragment>
					<Row>
						<div id="logo"></div>
					</Row>
					<p className="content_block">Loading...</p>
				</Fragment>
			}
			{(isLoaded && !hasError) && 
				<Fragment>
					<Row>
						<h2 className="subtitle_block">{project}</h2>
						<p className='subtitle_block'><strong>{data.length}</strong> systems</p>
					</Row>
					<Row>
						<div style={{borderBottom:"black 4px solid", marginBottom: "1rem"}}></div>
					</Row>
					{ Object.entries(nested).length > 1 &&
						<Fragment>
							<Row clss="row_header">
								<Column width="3"><h3>Room</h3></Column>
								<Column width="9"><h3># of Systems</h3></Column>
							</Row>
							<Row>
								<div style={{borderBottom:"black 3px solid", marginBottom: "2rem"}}></div>
							</Row>
						</Fragment>
					}
					{setRooms()}
				</Fragment>
			}
			{hasError && <p className="content_block">Error!</p>}
		</Container>
	);
}

export default ProjectPage;
import React, { Fragment } from 'react'

import { Row, Column } from '../components/skeleton.jsx'
import logo from "../ori_logo.svg"

const Topbar = () => {
	return (
		<Fragment>
			<Row>
				<Column width="2">
					<img src={logo} style={{width: "100%"}} alt="Ori Logo"></img>
				</Column>
				<Column width="1">
					<p style={{fontSize: "4rem", fontWeight: "100", marginBottom: "1rem"}}>Punchlist</p>
				</Column>
			</Row>
			<Row>
				<div style={{borderBottom:"black 4px solid", marginBottom: "1rem"}}></div>
			</Row>
		</Fragment>
	)
}

export default Topbar;
import React, { useState, useEffect, Fragment, useContext } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Container, Row, Column } from '../components/skeleton.jsx'
import Topbar from '../components/topbar.jsx'
import ImagePreview from '../components/image_preview.jsx'

import { AppContext } from '../app.jsx'
import uploading from "../assets/icons/icon_uploading_grey_large.svg"

const projects_url = "https://n42rss2i5d.execute-api.us-east-1.amazonaws.com/Prod/project/?project="
const signer_url = "https://n42rss2i5d.execute-api.us-east-1.amazonaws.com/Prod/system/files"
const disaster_url = "https://n42rss2i5d.execute-api.us-east-1.amazonaws.com/Prod/system/disaster"

const DisasterPage = (props) => {
	const { project, system } = useParams();
	const [images, setImages] = useState([]);
	const [submitted, setSubmitted] = useState(false);
	const [passed_data,,,,] = useContext(AppContext);
	const [hasError, setHasError] = useState(false);

	const [isUploading, setIsUploading] = useState(false);

	const [reason, setReason] = useState("");
	const [roomNumber, setRoom] = useState();
	const [sku, setSku] = useState();

	const [partNumber, setPartNumber] = useState();
	const [description, setDescription] = useState();
	
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		if(images.length > 0){
			console.log("should upload")
			console.log(images)
			const imgs = await imageUpload(images)
			console.log(imgs)
			if(imgs){
				console.log(imgs)
				let payload = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json; charset=UTF-8',
						"Accept": "*/*",
						"Accept-Encoding": "gzip, deflate, br"
					},
					body: JSON.stringify({
						project: project,
						part: partNumber,
						reason: reason,
						comments: description,
						room: roomNumber,
						files: imgs
					})
				}
				const res = await fetch(`${disaster_url}?system=${system}`, payload).then(response => response.json());
				navigate('/project/' + project);
			}
		} else {
			let payload = {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json; charset=UTF-8',
					"Accept": "*/*",
					"Accept-Encoding": "gzip, deflate, br"
				},
				body: JSON.stringify({
					project: project,
					part: partNumber,
					reason: reason,
					comments: description,
					room: roomNumber
				})
			}
			const res = await fetch(`${disaster_url}?system=${system}`, payload).then(response => response.json());
			navigate('/project/' + project);
		}
	}

	const processFiles = (e) => {
		if(e.target.files.length <= 6){
			let file_arr = Array.from(e.target.files);
			if(images.length > 0){
				let current_files = [...images];
				file_arr.forEach((file, i) => {
					if(!current_files.some(e => e.name === file.name && e.lastModified === file.lastModified)){
						// appends to existing array if new file
						current_files.push(file)
					}
					if(i == file_arr.length - 1 && current_files.length <= 6){
						setImages(current_files)
					} else if(i == file_arr.length - 1){
						alert("You can only upload up to " + 6 + " images.")
					}
				})
			} else {
				setImages(file_arr)
			}
		} else {
			alert("You can only upload up to 6 images.")
		}
	}

	const generateBlob = async (file) => new Blob([ new Uint8Array(await file.arrayBuffer()) ], { type: file.type });

	const imageUpload = async (images) => {
		if(images.length > 0){
			let files_send = [];
			let num_new_files = 0; // if new files are in the array
			images.forEach((file, ind) => {
				// ind is for the original location in val
				if(file instanceof File){
					num_new_files++; // there are new files to be uploaded
					files_send.push({ ind: ind, name: file.name, type: file.type, lastModified: file.lastModified, isUploaded: false })
				}
			})
			if(num_new_files > 0){
				setIsUploading(true);
				let payload = {
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json; charset=UTF-8',
						"Accept": "*/*",
						"Accept-Encoding": "gzip, deflate, br"
					},
					body: JSON.stringify({ files: files_send })
				}
				console.log(payload)

				let response_arr = [...images];
				console.log("original response array", response_arr);
	
				try {
					let signed_urls = await fetch(signer_url, payload).then(response => response.json())
					console.log(signed_urls)
					if(signed_urls.length > 0) {
						for (const [i, file] of signed_urls.entries()){
							console.log([i, file])
							try {
								let blobbers = await generateBlob(images[files_send[i].ind]);
								const file_data = {
									...file.fields,
									"Content-Type": file.type,
									file: blobbers
								}
								const form_data = new FormData()
								for (const name in file_data){
									form_data.append(name, file_data[name])
								}
								
								try {
									await fetch(file.url, {
										method: 'POST',
										body: form_data
									})
									response_arr[files_send[i].ind] = file_data["key"]
									if(i == num_new_files - 1){
										console.log("response is ready")
										return response_arr;
									}
								} catch (e) {
									console.error(e);
									return false;
								}
							} catch (err) {
								console.error(err);
								return false;
							}
						}
					}
				} catch (error) {
					console.error(error);
					return false;
				}
			} else {
				return images;
			}
		}
	};

	const removePic = (file_ind) => {
		let current_files = [...images];
		let new_array = current_files.filter((file, i) => { return file_ind != i});
		//setHasChanged(true);
		setImages(new_array);
	}

	useEffect(() => {
		if(passed_data == null || passed_data.length == 0){
			//console.log("fetching data")
			fetch(projects_url + project)
				.then(res => res.json())
				.catch(err => setHasError(true))
				.then((result) => {
					let arr = result.sort((a,b) => (a.room > b.room) ? 1 : -1);
					result = arr;
					let individual_sys = result.find(sys => sys.system === system);
					setRoom(individual_sys.room); setSku(individual_sys.sku);
				});
		} else {
			//console.log("parsing data passed from APP")
			const individual_sys = passed_data.find(sys => sys.system === system);
			if(!roomNumber || !sku){
				setRoom(individual_sys.room); setSku(individual_sys.sku);
			}
		}
	}, [passed_data])

	const handlePrev = () => {
		if(reason){
			setReason()
		} else {
			navigate(`/project/${project}`);
		}
	}

	const btn_style = {
		width: "100%",
		height: "9.5rem",
		fontSize: "3.5rem",
		fontWeight: 400,
		marginBottom: "4rem",
		lineHeight: "10.25rem",
		borderRadius: "4.75rem",
		border: "2px #222222 solid",
		backgroundColor: "white"
	}

	const displayReasonPicker = () => {
		return (
			<Fragment>
				<Row>
					<h2>What brings you here?</h2>
				</Row>
				<Row>
					<button style={btn_style} onClick={() => setReason("missing")}>Something's Missing</button>
				</Row>
				<Row>
					<button style={btn_style} onClick={() => setReason("broken")}>Something's Broken</button>
				</Row>
			</Fragment>
		)
	}

	return (
		<Container>
			<header>
				<Topbar></Topbar>
				<Fragment>
					<div className="step_header">
						<Row>
							<Column width="3">
								<p className='subtitle_block'>Room #</p>
								<h2 className="subtitle_block"><strong>{roomNumber}</strong></h2>	
							</Column>
							<Column width="9">{sku && 
								<h3 className="subtitle_block">
									<strong>{sku.split(",")[0]}</strong><br />{sku.split(",").slice(1, sku.split(",").length)}
								</h3>}
							</Column>
						</Row>
					</div>
					<Row>
						<div style={{borderBottom:"black 2px solid", marginBottom: "1rem"}}></div>
					</Row>
				</Fragment>
			</header>
			<section>
				<Row>
					{!reason && displayReasonPicker() }
					{reason &&
						<form className="form_fields">
							<label htmlFor="reason"><strong>Part Number</strong><br></br>Please refer to the manual to find the part number of the missing component</label>
							
							<input required id="partNumber" value={partNumber} onChange={e => setPartNumber(e.target.value)} maxLength="80" name="partNumber" size="20" type="text" />

							{reason === "broken" &&
								<Fragment>
								<label htmlFor="reason"><strong>Pictures</strong> (Max of 6)</label>
								{!isUploading ? 
									<Fragment>
										<div className="pic_upload" /*onClick={() => {scrollToBottom()}}*/>
											<input type="file" accept="image/*" capture onChange={e => { processFiles(e); /*scrollToBottom();*/}} multiple={true} disabled={false}/>
										</div>
									</Fragment> :
									<div style={{textAlign: "center", marginTop: "4rem", marginBottom: "4rem"}}>
										<div className='uploading'>
											<div className='border'></div>
											<img alt={uploading} src={uploading}></img> 
										</div>
										<p style={{marginTop: "1rem", fontSize: "3rem", marginBottom: "0", fontWeight: "300"}}>Uploading...</p>
									</div>}
								<ImagePreview files={images} onDelete={removePic}/>
								</Fragment>
							}

							<label htmlFor="description"><strong>Description</strong> (Optional)</label>
							<textarea required name="description" value={description} onChange={e => setDescription(e.target.value)}></textarea>
						</form>
					}
				</Row>
				<Row><div className="button_accordion">
					<Column width="5">
						<button className="prev_button" onClick={handlePrev}>Back</button>
					</Column>
					<Column width="5" offset="2">
						{reason &&
							<button className={"next_button" + (isUploading ? " uploading" : "")} disabled={
								!(partNumber && (reason === "broken" ? images.length > 0 : true))
							} onClick={handleSubmit}>Submit</button>
						}
					</Column>
				</div></Row>
			</section>
		</Container>
	)
}

export default DisasterPage;
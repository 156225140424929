import React from 'react'
import { Row, Column } from '../components/skeleton.jsx'

const ImagePreview = ({files, onDelete}) => {
	const style = {
		border: "black 2px dashed",
		backgroundColor: "white",
		height: "12rem",
		overflow: "hidden",
		borderRadius: "2rem",
		paddingTop: "1rem",
		width: "calc(100%-2px)"
	}
	const displayImgs = () => {		
		let return_arr = [];
		if(files.length > 0 && Array.isArray(files)){
			files.forEach((file, ind) => {
				if(file instanceof File){
					return_arr.push(
						<Column width="2" key={file.name}>
							<div style={{position: "relative"}}>
								<div className="close_btn" onClick={() => {onDelete(ind)}}><strong>X</strong></div>
								<div className="img_preview" >
									<img src={URL.createObjectURL(file)} style={{width: "100%", verticalAlign: "middle"}}></img>
								</div>
							</div>
						</Column>
					)
				} else {
					// if this is from API
					return_arr.push(
						<Column width="2" key={file.key ? file.key : "file_" + ind}>
							<div style={{position: "relative"}}>
								<div className="close_btn" onClick={() => {onDelete(ind)}}><strong>X</strong></div>
								<div className="img_preview">
									<h4>{file.key}</h4>
								</div>
							</div>
						</Column>
					)
				}
			})
			return return_arr;
		} else {
			return <h2 style={{lineHeight: "12rem", margin: "0", color: "grey"}}>No Pictures</h2>
		}
	}
	return (
		<Row>
			<div style={style}>{displayImgs()}</div>
		</Row>
	)
}

export default ImagePreview;